import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/Layout";
import SEO from "../components/SEO";

const TermsofService = ({ data }) => {

	const termsofservices = data.termsofservices.nodes;
	const termsofservices_vi = data.termsofservices_vi.nodes;

	return (
		<Layout>
            <SEO title="Terms of Service" description="Appvity Terms of service to inform your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who access or use the Service." meta={[{ name: `keywords`, content: ["Appvity terms of service"] }]} pathname="/terms-of-service" />
			<div className="main-content-padding">
				<div className="app-text-template-introduction terms-of-service" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <h2>Terms Of Service</h2>
                    </Container>
                </div>
				<div className="app-general-seciton app-text-template-body" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
							<Col xs={12} md={12} lg={1}></Col>
							<Col xs={12} md={12} lg={3}>
								<ul className="app-text-template-body-detail-left">
									{termsofservices.map((res) => (
										<li className="app-data-en" 
											key={res.id} 
											onClick={() => {
												var scrollDiv = document.getElementById(res.frontmatter.keyNumber).offsetTop + 340;
												window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
											}}
										>{res.frontmatter.title}</li>
									))}
									{termsofservices_vi.map((res) => (
										<li className="app-data-vi" 
											key={res.id} 
											onClick={() => {
												var scrollDiv = document.getElementById(res.frontmatter.keyNumber).offsetTop + 340;
												window.scrollTo({ top: scrollDiv, behavior: 'smooth'});
											}}
										>{res.frontmatter.title}</li>
									))}
								</ul>
							</Col>
							<Col xs={12} md={12} lg={7}>
								<ul className="app-text-template-body-detail-right">
									{termsofservices.map((res) => (
										<li className="app-data-en" key={res.id} id={res.frontmatter.keyNumber}><h5>{res.frontmatter.title}</h5>
											<div dangerouslySetInnerHTML={{ __html: res.html }} />
										</li>
									))}
									{termsofservices_vi.map((res) => (
										<li className="app-data-vi" key={res.id} id={res.frontmatter.keyNumber}><h5>{res.frontmatter.title}</h5>
											<div dangerouslySetInnerHTML={{ __html: res.html }} />
										</li>
									))}
								</ul>
							</Col>
							<Col xs={12} md={12} lg={1}></Col>
						</Row>
                    </Container>
                </div>
			</div>
		</Layout>
	)
}

export default TermsofService;

export const query = graphql`
    query TermsofServicePage {
        termsofservices: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "termsofservices" } } }
			sort: { fields: frontmatter___createAt, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
					keyNumber
                }
                id
            }
        }
        termsofservices_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "termsofservices_vi" } } }
			sort: { fields: frontmatter___createAt, order: ASC }
        ) {
            nodes {
                html
                frontmatter {
                    title
					keyNumber
                }
                id
            }
        }
    }
`;